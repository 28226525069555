import React from "react";
import WipeBtnBlack from "../../components/atoms/buttons/WipeBtnBlack";

const SolutionsWrapperRevamped = ({ img, heading, para, btnText }) => {
  return (
    <div className="solutions-header-wrapper" style={{ marginBottom: "200px" }}>
      <div className="header-background-image-wrapper">
        <img src={img} alt="backgroundimg" />
        <div className="heading-content">
          <div className="heading-contnet-inner">
            <div className="heading-title heading"> {heading}</div>
            <div className="heading-para para">{para}</div>
          </div>
          <WipeBtnBlack text="Talk To An Expert" url="/contactus/" />
        </div>
      </div>
      <div className="header-background-image-mobile">
        <img src={img} alt="backgroundimg" />
      </div>
    </div>
  );
};
export default SolutionsWrapperRevamped;
